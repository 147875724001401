import { lazy } from "react";

////// protect routes

// axios.defaults.baseURL = configAppConst.APIBaseURL;
export const scriptWithoutDot = [
  "GOLD",
  "GOLDM",
  "SILVER",
  "SILVERM",
  "CRUDEOIL",
];
export const scriptWithDot = [
  "ALUMINIUM",
  "COPPER",
  "LEAD",
  "NICKEL",
  "ZINC",
  "NATURALGAS",
];
export const scriptWithDotAccept = [
  "00",
  "05",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
  "60",
  "65",
  "70",
  "75",
  "80",
  "85",
  "90",
  "95",
];
export const SuperAdminRouters = [
  "/settings/banned-scripts",
  "/settings/script-management",
  "/settings/block-scripts",
  "/admin/authority",
  "/admin/authority/add",
  "/admin/system-users",
  "/admin/notification-rights",
  "/admin/trial-balance",
  "/transaction/bill-creation",
  "/transaction/upload-bhavCopy",
   "/profile/settings",
   "/finance/bank-details",
];
export const addNewInsertPageAuthority = [
  "/profile/change-password",
  "/position-report2",
  "/master/account/add",
  "/master/account/instant-account",
  "/master/account/edit",
  "/master/brokerage/add",
  "/master/brokerage/edit",
  "/master/partnership/add",
  "/master/partnership/edit",
  "/master/valan/add",
  "/master/valan/edit",
  "/profile/rules",
  "/profile/privacy-policy",
];
export const AdminRouters = [
  ...addNewInsertPageAuthority,
  "/admin/authority-rights",
  "/profile/settings",
  "/finance/bank-details",
];
export const MasterRouters = [
  ...addNewInsertPageAuthority,
  "/admin/system-user-map",
  "/admin/authority-rights",
  "/settings/script-management",
  "/profile/settings",
  "/finance/bank-details"
];
export const viewUserAuthorityPath = [
  "/profile/change-password",
  "/master/account/add",
  "/master/account/instant-account",
  "/master/brokerage",
  "/master/brokerage/edit",
  "/master/partnership",
  "/master/partnership/add",
  "/master/partnership/edit",
  "/master/valan",
  "/master/valan/add",
  "/master/valan/edit",
  "/transaction/upload-bhavCopy",
  "/transaction/bill-creation",
  "/transaction/trade-utility",
  "/transaction/trade-entry",
  "/settings/transfer",
  "/settings/expiry-validation",
  "/settings/script-max-value",
  "/settings/client-segment",
  "/settings/client-order-limit",
  "/settings/script-max-qty-setting",
  "/settings/cPanel",
  "/settings/call-put-setting",
  "/settings/option-strike-validation"
];
export const defaultNotViewAuthority = [
  "/settings/script-max-qty-setting",
  "/settings/script-max-value",
  "/settings/expiry-validation",
  "/settings/banned-scripts",
  "/admin/trial-balance",
  "/settings/option-strike-validation",
  "/transaction/upload-bhavCopy",
  "/transaction/bill-creation",
  "/transaction/trade-utility",
  "/transaction/trade-entry",
  "/master/valan",
  "/settings/cPanel",
];
export const userAuthority = [
  "/",
  "/marketwatch",
  "/order-book",
  "/trade-book",
  "/position-report",
  "/ledger-report",
  "/trade-report",
  "/rejection-log",
  "/log-report",
  "/max-quantity-details",
  "/banned-blocked-scripts",
];
export const userAuthorityPath = [
  "/",
  "/marketwatch",
  "/order-book",
  "/trade-book",
  "/position-report",
  "/reports/ledger-report",
  "/reports/trade-report",
  "/reports/rejection-log",
  "/reports/log-report",
  "/max-quantity-details",
  "/banned-blocked-scripts",
  "/profile/change-password",
  "/profile/rules",
  "/profile/privacy-policy",
];
export const partnerAuthority = [
  "/",
  "/marketwatch",
  "/order-book",
  "/trade-book",
  "/position-report",
  "/max-quantity-details",
  "/banned-blocked-scripts",
  "/ledger-report",
  "/trade-report",
];
export const partnerAuthorityPath = [
  "/",
  "/marketwatch",
  "/order-book",
  "/trade-book",
  "/position-report",
  "/max-quantity-details",
  "/banned-blocked-scripts",
  "/reports/ledger-report",
  "/reports/trade-report",
  "/profile/change-password",
  "/profile/rules",
  "/profile/privacy-policy",
];
export const mobileAuthority = [
  "/app",
  "/app/order-book",
  "/app/trade-book",
  "/app/position-report",
  "/app/rejection-logs",
  "/app/trade-edit-delete-logs",
  "/app/qty-setting",
  "/app/qty-setting/qty-inner-setting",
  "/app/add-script",
  "/app/confirm-order",
  "/app/add-script/script",
  "/app/change-password",
  "/app/banned-block-script",
  "/app/rules",
  "/app/order-detail",
  "/app/close-position",
  "/app/position-detail",
  "/app/add-marketwatch-script",
  "/app/trade-report",
  "/app/ledger-report",
  "/app/profile",
];
export const subBrokerAuthority = [
  "/",
  "/marketwatch",
  "/order-book",
  "/trade-book",
  "/position-report",
  "/max-quantity-details",
  "/banned-blocked-scripts",
  "/ledger-report",
  "/trade-report"
];
export const subBrokerAuthorityPath = [
  "/",
  "/marketwatch",
  "/order-book",
  "/trade-book",
  "/position-report",
  "/max-quantity-details",
  "/banned-blocked-scripts",
  "/reports/ledger-report",
  "/reports/trade-report",
  "/profile/change-password",
  "/profile/rules",
  "/profile/privacy-policy",
];
export const isViewAuthority = [
  "/home",
  "/settings/script-max-qty-setting",
  "/settings/client-order-limit",
  "/settings/client-segment",
  "/settings/script-max-value",
  "/settings/expiry-validation",
  "/settings/transfer",
  "/settings/block-scripts",
  "/settings/banned-scripts",
  "/settings/quantity",
  "/settings/script-management",
  "/reports/rejection-log",
  "/reports/log-report",
  "/admin/trial-balance",
  "/reports/ledger-report",
  "/reports/trade-report",
  "/settings/option-strike-validation",
  "/finance/jv-entry",
  "/finance/pay-in",
  "/finance/pay-out",
  "/finance/bank-details",
  "/transaction/upload-bhavCopy",
  "/transaction/bill-creation",
  "/transaction/trade-utility",
  "/transaction/trade-entry",
  "/master/valan",
  "/master/partnership",
  "/master/brokerage",
  "/master/account",
  "/max-quantity-details",
  "/banned-blocked-scripts",
  "/position-report",
  "/trade-book",
  "/order-book",
  "/marketwatch",
  "/finance/cash-entry",
  "/settings/cPanel",
  "/settings/call-put-setting",
  "/inspect/same-ip",
  "/inspect/trade-inspection",
  "/inspect/top-gainers-and-losers"
];
export const isSaveAuthority = [
  "/settings/script-max-qty-setting",
  "/settings/client-order-limit",
  "/settings/client-segment",
  "/settings/script-max-value",
  "/settings/expiry-validation",
  "/settings/block-scripts",
  "/settings/banned-scripts",
  "/settings/quantity",
  "/settings/script-management",
  "/settings/option-strike-validation",
  "/finance/jv-entry",
  "/finance/pay-in",
  "/finance/pay-out",
  "/finance/bank-details",
  "/transaction/upload-bhavCopy",
  "/transaction/trade-utility",
  "/transaction/trade-entry",
  "/master/valan",
  "/master/partnership",
  "/master/brokerage",
  "/master/account",
  "/marketwatch",
  "/finance/cash-entry",
  "/settings/call-put-setting",
];
export const isUpdateAuthority = [
  "/settings/client-segment",
  "/transaction/trade-entry",
  "/finance/jv-entry",
  "/finance/pay-out",
  "/finance/pay-in",
  "/finance/bank-details",
  "/finance/bank-details",
  "/finance/cash-entry",
  "/master/valan",
  "/master/partnership",
  "/master/brokerage",
  "/master/account",
  "/order-book",
  "/settings/cPanel",
  "/settings/call-put-setting",
];
export const isDeleteAuthority = [
  "/settings/script-max-qty-setting",
  "/settings/client-order-limit",
  "/settings/client-segment",
  "/settings/script-max-value",
  "/settings/expiry-validation",
  "/settings/block-scripts",
  "/settings/banned-scripts",
  "/settings/quantity",
  "/settings/script-management",
  "/settings/option-strike-validation",
  "/finance/jv-entry",
  "/finance/pay-in",
  "/finance/pay-out",
  "/finance/bank-details",
  "/transaction/trade-entry",
  "/finance/cash-entry",
  "/finance/bank-details",
  "/master/partnership",
  "/master/brokerage",
  "/master/account",
  "/order-book",
  "/marketwatch",
  "/settings/call-put-setting",
];
export const SubBrokerRouters = [
  "/views/Settings",
  "/master/account/add",
  "/master/account/instant-account",
  "/master/account",
  "/master/brokerage/add",
  "/master/brokerage",
  "/master/partnership",
  "/master/partnership/add",
  "/finance/cash-entry",
  "/finance/bank-entry",
  "/finance/jv-entry",
  "/finance/pay-in",
  "/finance/pay-out",
  "/reports/trade-report",
  "/reports/ledger-report",
  "/reports/log-report",
  "/reports/rejection-log",
  "/settings/quantity",
  "/settings/transfer",
  "/settings/expiry-validation",
  "/settings/script-max-value",
  "/settings/client-segment",
  "/settings/client-order-limit",
  "/master/valan",
  "/master/valan/add",
  "/settings/script-max-qty-setting",
  "/settings/option-strike-validation",
  "/settings/cPanel",
];
export const AuthRoutes = [
  // {
  //   path: "/",
  //   component: () => <Redirect to="/home" />,
  // },
  {
    layout: "/admin",
    path: "/home",
    exact: true,
    component: lazy(() => import("views/home/Index")),
  },
  {
    layout: "/admin",
    path: "/marketwatch",
    exact: true,
    component: lazy(() => import("views/MarketWatch")),
  },
  {
    layout: "/admin",
    path: "/order-book",
    component: lazy(() => import("views/OrderBook")),
  },
  {
    layout: "/admin",
    path: "/position-report",
    component: lazy(() => import("views/PositionReport")),
  },
  {
    layout: "/admin",
    path: "/trade-book",
    component: lazy(() => import("views/TradeBook")),
  },
  {
    layout: "/admin",
    path: "/profile/change-password",
    component: lazy(() => import("views/profile/ChangePassword")),
  },
  {
    layout: "/admin",
    path: "/profile/settings",
    component: lazy(() => import("views/profile/Settings")),
  },
  {
    layout: "/admin",
    path: "/master/account/add",
    component: lazy(() => import("views/master/MasterAccountAdd")),
  },
  {
    layout: "/admin",
    path: "/master/account",
    component: lazy(() => import("views/master/MasterAccount")),
  },
  {
    layout: "/admin",
    path: "/master/brokerage/add",
    component: lazy(() => import("views/master/MasterBrokerageAdd")),
  },
  {
    layout: "/admin",
    path: "/master/brokerage",
    component: lazy(() => import("views/master/MasterBrokerage")),
  },
  {
    layout: "/admin",
    path: "/master/partnership",
    component: lazy(() => import("views/master/MasterPartnership")),
  },
  {
    layout: "/admin",
    path: "/master/partnership/add",
    component: lazy(() => import("views/master/MasterPartnershipAdd")),
  },
  {
    layout: "/admin",
    path: "/transaction/trade-entry",
    component: lazy(() => import("views/transaction/TradeEntry")),
  },
  {
    layout: "/admin",
    path: "/transaction/trade-utility",
    component: lazy(() => import("views/transaction/TradeUtility")),
  },
  {
    layout: "/admin",
    path: "/transaction/bill-creation",
    component: lazy(() => import("views/transaction/BillCreation")),
  },
  {
    layout: "/admin",
    path: "/transaction/upload-bhavCopy",
    component: lazy(() => import("views/transaction/UploadBhavCopy")),
  },
  {
    layout: "/admin",
    path: "/finance/cash-entry",
    component: lazy(() => import("views/finance/FinanceCashEntry")),
  },
  {
    layout: "/admin",
    path: "/finance/bank-entry",
    component: lazy(() => import("views/finance/FinanceBankEntry")),
  },
  {
    layout: "/admin",
    path: "/finance/jv-entry",
    component: lazy(() => import("views/finance/FinanceJVEntry")),
  },
  {
    layout: "/admin",
    path: "/finance/pay-in",
    component: lazy(() => import("views/finance/PayIn")),
  },
   {
    layout: "/admin",
    path: "/pay-out",
    component: lazy(() => import("views/finance/PayOut")),
  },
  {
    layout: "/admin",
    path: "/reports/trade-report",
    component: lazy(() => import("views/report/ReportTrade")),
  },
  {
    layout: "/admin",
    path: "/reports/ledger-report",
    component: lazy(() => import("views/report/ReportLedger")),
  },
  {
    layout: "/admin",
    path: "/reports/log-report",
    component: lazy(() => import("views/report/ReportUpdateLog")),
  },
  {
    layout: "/admin",
    path: "/reports/rejection-log",
    component: lazy(() => import("views/report/ReportRejectionLog")),
  },
  {
    layout: "/admin",
    path: "/settings/quantity",
    component: lazy(() => import("views/settings/SettingQty")),
  },
  {
    layout: "/admin",
    path: "/settings/banned-scripts",
    component: lazy(() => import("views/settings/SettingBlockScript")),
  },
  {
    layout: "/admin",
    path: "/settings/block-scripts",
    component: lazy(() => import("views/settings/SettingScript")),
  },
  {
    layout: "/admin",
    path: "/settings/transfer",
    component: lazy(() => import("views/settings/SettingTransfer")),
  },
  {
    layout: "/admin",
    path: "/settings/expiry-validation",
    component: lazy(() => import("views/settings/SettingExpiry")),
  },
  {
    layout: "/admin",
    path: "/settings/script-max-value",
    component: lazy(() => import("views/settings/SettingScriptMax")),
  },
  {
    layout: "/admin",
    path: "/settings/client-segment",
    component: lazy(() => import("views/settings/SettingSegment")),
  },
  {
    layout: "/admin",
    path: "/settings/client-order-limit",
    component: lazy(() => import("views/settings/SettingClientOrder")),
  },

  {
    layout: "/admin",
    path: "/banned-blocked-scripts",
    exact: true,
    component: lazy(() => import("views/BannedBlockedScripts")),
  },
  {
    layout: "/admin",
    path: "/max-quantity-details",
    component: lazy(() => import("views/MaxQuantityDetails")),
  },
  {
    layout: "/admin",
    path: "/master/valan",
    exact: true,
    component: lazy(() => import("views/master/MasterValan")),
  },
  {
    layout: "/admin",
    path: "/master/valan/add",
    exact: true,
    component: lazy(() => import("views/master/MasterValanAdd")),
  },
  {
    layout: "/admin",
    path: "/profile/rules",
    exact: true,
    component: lazy(() => import("views/profile/Rules")),
  },
  {
    layout: "/admin",
    path: "/settings/script-max-qty-setting",
    exact: true,
    component: lazy(() => import("views/settings/SettingScriptMaxQty")),
  },
  {
    layout: "/admin",
    path: "/settings/option-strike-validation",
    exact: true,
    component: lazy(() =>
      import("views/settings/SettingOptionStrikeValidation")
    ),
  },
  {
    layout: "/admin",
    path: "/settings/cPanel",
    exact: true,
    component: lazy(() => import("views/settings/SettingCPanel")),
  },
  {
    layout: "/admin",
    path: "/admin/client-map",
    exact: true,
    component: lazy(() => import("views/settingSuperAdmin/ClientMap")),
  },
  {
    layout: "/admin",
    path: "/admin/authority-rights",
    exact: true,
    component: lazy(() => import("views/settingSuperAdmin/AuthorityRights")),
  },
  {
    layout: "/admin",
    path: "/admin/trial-balance",
    exact: true,
    component: lazy(() => import("views/report/TrialBalance")),
  },
  // {
  //   layout: "/admin",
  //   path: "*",
  //   component: () => <Redirect to="/home" />,
  // },
];

export const UnAuthRoutes = [
  {
    layout: "/auth",
    path: "/",
    exact: true,
    component: lazy(() => import("views/auth/Login")),
  },
  {
    layout: "/auth",
    path: "/clogin",
    exact: true,
    component: lazy(() => import("views/auth/CLogin")),
  },
  {
    layout: "/auth",
    path: "/auth/register",
    component: lazy(() => import("views/auth/Register")),
  },
  {
    layout: "/yash",
    path: "/tc",
    component: lazy(() => import("views/auth/TermsAndCondition")),
  },
];
