/*eslint-disable*/
import { useEffect, useMemo, useState } from "react";
import { NavLink as NavLinkRRD, Link, useLocation } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "store/reducers/auth.slice";
import { userPartnerEnum } from "appConfigurations";
import devtools from "../../../node_modules/devtools-detect/index";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
import configAppConst from "appConfigurations";
import { getSessionDataOnlyRead } from "utils/common";
import Favicon from "../../assets/img/favicon.png";
import UplineImg from "../../assets/img/icons/customer-service.svg";
import {
  getNotificationList,
  getRightsForSession,
} from "store/reducers/common.slice";
import { getSessionData } from "utils/common";
import { setIsOrderBookGet } from "store/reducers/orderbook.slice";
import { setIsTradeBookGet } from "store/reducers/tradeBook.slice";
import {
  setIsPositionGet,
  setIsPositionGet2,
  setPositionReportFilterValue,
  setPositionReportFilterValue2,
} from "store/reducers/positionReport.slice";
import { setIsQuantityGet } from "store/reducers/settingsSlice/setting.slice";
import { setIsSettingOptionStrikeGet } from "store/reducers/settingsSlice/settingOptionStrikeValidation.slice";
import { setIsSettingSymbolMaxValueGet } from "store/reducers/settingsSlice/settingSymbolMaxValue.slice";
import { setIsSettingClientSymbolGet } from "store/reducers/settingsSlice/settingClientSegment.slice";
import { setIsSettingClientOrderLimitGet } from "store/reducers/settingsSlice/settingClientOrderLimit.slice";
import {
  setIsFilterRejectionLogData,
  setIsFilterDataUpdateLog,
} from "store/reducers/report.slice";
import { setHomeDashboardList } from "store/reducers/home.slice";
import {
  setUserDevice,
  setRulesAccepted,
  setIsShowChangePassword,
} from "store/reducers/auth.slice";

const Sidebar = (props) => {
  const [isMdScreen, setIsMdScreen] = useState(true);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMdScreen(false);
    } else {
      setIsMdScreen(true);
    }
  }, []);
  const { bgColor, routes, logo } = props;
  return (
    <>
      <Navbar
        className={
          props?.isNavbarHidden
            ? "navbar-vertical fixed-left navbar-light navbar-hidden"
            : "navbar-vertical fixed-left navbar-light"
        }
        expand="md"
        id="sidenav-main"
      >
        {isMdScreen && (
          <Scrollbars className="w-100 min-vh-100 custome_Scrollbar" autoHide>
            <SidebarContent bgColor={bgColor} routes={routes} logo={logo} />
          </Scrollbars>
        )}
        {!isMdScreen && (
          <SidebarContent bgColor={bgColor} routes={routes} logo={logo} />
        )}
      </Navbar>
    </>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

function SidebarContent(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { rightsSessionList, notificationList } = useSelector(
    ({ common }) => common
  );
  const { userIp, userRole, isMasterRole } = useSelector(({ auth }) => auth);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [masterCollapse, setMasterCollapse] = useState(false);
  const [transactionCollapse, setTransactionCollapse] = useState(false);
  const [inspectCollapse, setInspectCollapse] = useState(false);
  const [financeCollapse, setFinanceCollapse] = useState(false);
  const [reportCollapse, setReportCollapse] = useState(false);
  const [settingCollapse, setSettingCollapse] = useState(false);
  const [newsDropDown, setNewsDropDown] = useState(false);
  const [selectedTab, setSelectedTab] = useState(location?.pathname);
  const [openModalWarning, setOpenModalWarning] = useState(false);

  const deviceType = useMemo(() => {
    const detectDeviceType = () => {
      if (
        /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      )
        return "mobile";
      else if (/iPad|iPod/i.test(navigator.userAgent)) return "tablet";
      else return "browser";
    };
    return detectDeviceType();
  }, []);

  useEffect(() => {
    if (deviceType === "browser" && devtools?.isOpen) {
      setOpenModalWarning(true);
      setInterval(() => {
        if (!devtools?.isOpen) {
          return;
        }
        debugger;
      }, 500);
    }
  }, []);

  useMemo(() => {
    window.addEventListener("devtoolschange", (event) => {
      if (deviceType === "browser" && event.detail.isOpen) {
        setOpenModalWarning(true);
        setInterval(() => {
          if (!event.detail.isOpen) {
            return;
          }
          debugger;
        }, 500);
      } else {
        setOpenModalWarning(false);
      }
    });
  }, []);

  useEffect(() => {
    const { roles, userId } = getSessionData();
    if (roles[0] !== "User") {
      dispatch(getRightsForSession(userId));
    }
    dispatch(getNotificationList());
  }, []);
  useEffect(() => {
    setSelectedTab(location.pathname);
  }, [location]);
  // verifies if routeName is the one active (in browser input)
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  const { logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  let storeUserName = getSessionDataOnlyRead();
  const isAllowShow = (routeName) => {
    if (
      (routeName === "/reports/rejection-log" ||
        routeName === "/reports/rejection-log") &&
      userRole === "User"
    ) {
      return true;
    }
    if (userRole === "Super Admin") {
      return true;
    } else if (userRole !== "User") {
      return true;
    } else {
      let arr = rightsSessionList?.filter((a) => a.routeName == routeName);
      if (arr.length > 0) {
        return arr[0].isViewAllow;
      } else {
        return false;
      }
    }
  };

  const isMasterAllowShow = (routeArray) => {
    if (userRole === "Super Admin" || userRole === "User") {
      return true;
    } else {
      let b =
        rightsSessionList.length > 0 &&
        rightsSessionList
          .filter((a) => routeArray.includes(a.routeName))
          .some((a) => {
            return String(a.isViewAllow) == "true";
          });
      return b;
    }
  };
  const handleLogOut = async () => {
    await dispatch(setIsOrderBookGet(false));
    await dispatch(setIsTradeBookGet(false));
    await dispatch(setIsPositionGet(false));
    await dispatch(setIsPositionGet2(false));
    await dispatch(setPositionReportFilterValue({}));
    await dispatch(setPositionReportFilterValue2({}));
    await dispatch(setIsQuantityGet(false));
    await dispatch(setIsSettingOptionStrikeGet(false));
    await dispatch(setIsSettingSymbolMaxValueGet(false));
    await dispatch(setIsSettingClientSymbolGet(false));
    await dispatch(setIsSettingClientOrderLimitGet(false));
    await dispatch(setIsFilterRejectionLogData(false));
    await dispatch(setIsFilterDataUpdateLog(false));
    await dispatch(setHomeDashboardList([]));
    await dispatch(logOut({ userIp }));
    await dispatch(setUserDevice(""));
    await dispatch(setRulesAccepted(true));
    await dispatch(setIsShowChangePassword(false));
  };
  return (
    <Container fluid>
      <Row className="justify-content-center">
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <i className="material-icons-outlined">menu</i>
        </button>

        {logo ? (
          <NavbarBrand
            className="pt-0 d-flex align-items-center"
            {...navbarBrandProps}
          >
            <img
              src={Favicon}
              style={{ height: "35px", width: "35px", marginRight: "5px" }}
              alt="favicon"
            />
            <h1 className="mb-0">TRADETECH365</h1>
          </NavbarBrand>
        ) : null}
        <Nav className="align-items-center d-md-none mobile_nav">
          {userRole !== "User" && (
            <UncontrolledDropdown>
              <div className="upline_mobile_btn">
                <img
                  src={UplineImg}
                  alt="UplineIcon"
                  onClick={async () => {
                    await dispatch(instantChangeActiveStatus());
                    await handleLogOut();
                    window.open("about:blank", "_self");
                    window.close();
                  }}
                />
              </div>
            </UncontrolledDropdown>
          )}
          <UncontrolledDropdown nav className="mb-0 mt-0 tv_dropdown_wrapper">
            <DropdownToggle
              nav
              className="nav-link-icon position-relative tv_dropdown"
            >
              <span className="material-symbols-outlined">desktop_windows</span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem onClick={() => setNewsDropDown(true)}>
                English Channel
              </DropdownItem>
              <DropdownItem onClick={() => setNewsDropDown(true)}>
                Hindi Channel
              </DropdownItem>
              <DropdownItem onClick={() => setNewsDropDown(true)}>
                Gujarati Channel
              </DropdownItem>
            </DropdownMenu>
            <div
              className={newsDropDown ? "tv_video_box show" : "tv_video_box"}
            >
              <div
                className="video_close_icon"
                onClick={() => setNewsDropDown(false)}
              >
                <span className="material-symbols-outlined">close</span>
              </div>
              <iframe
                width="300"
                height="150"
                src="https://www.youtube.com/embed/D0UnqGm_miA"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle
              nav
              className="nav-link-icon position-relative pr-0"
            >
              <i className="material-icons-outlined">account_circle</i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <h6 className="dropdown-header text-center bg-primary text-white">
                {storeUserName && storeUserName.userName}
                <div>{userRole}</div>
                {!isMasterRole && (
                  <span className="mreadonly_text">View Only</span>
                )}
              </h6>
              {isMasterRole && (
                <DropdownItem
                  to="/profile/change-password"
                  tag={Link}
                  active={location.pathname == "/profile/change-password"}
                >
                  <span>Change Password</span>
                </DropdownItem>
              )}
              {userRole == "Super Admin" && (
                <>
                  <DropdownItem
                    to="/admin/system-users"
                    tag={Link}
                    active={location.pathname == "/admin/system-users"}
                  >
                    <span>System Users</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/authority-rights" tag={Link}>
                    <span>Authority Rights</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/authority-limits" tag={Link}>
                    <span>Authority Limits</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/client-map" tag={Link}>
                    <span>Client Map</span>
                  </DropdownItem>
                  {isMasterRole && userRole === "Master" && (
                    <DropdownItem to="/admin/system-user-map" tag={Link}>
                      <span>System User Map</span>
                    </DropdownItem>
                  )}
                  <DropdownItem to="/profile/settings" tag={Link}>
                    <span>Settings</span>
                  </DropdownItem>
                </>
              )}
              <DropdownItem
                to="/profile/rules"
                active={location.pathname == "/profile/rules"}
                tag={Link}
              >
                <span>{configAppConst.rules}</span>
              </DropdownItem>
              <DropdownItem
                target="_blank"
                to="/profile/privacy-policy"
                active={location.pathname == "/profile/privacy-policy"}
                tag={Link}
              >
                <span>{configAppConst.privacyPolicy}</span>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  dispatch(logOut({ userIp }));
                }}
              >
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header mobile_sidebar d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand p-0">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img
                        src={Favicon}
                        style={{
                          height: "35px",
                          width: "35px",
                          marginRight: "5px",
                        }}
                        alt="favicon"
                      />
                      <h1 className="mb-0">TRADETECH365</h1>
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img
                        src={Favicon}
                        style={{
                          height: "35px",
                          width: "35px",
                          marginRight: "5px",
                        }}
                        alt="favicon"
                      />
                      <h1 className="mb-0">TRADETECH365</h1>
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <i className="material-icons-outlined">close</i>
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar className="sidebar-nav-transform">
            {(userRole === "User" ||
              userRole === "Partner" ||
              isAllowShow("/home")) && (
              <NavItem>
                <NavLink
                  className={selectedTab === "/" ? "current" : ""}
                  to="/"
                  tag={NavLinkRRD}
                  onClick={() => {
                    closeCollapse();
                    setSelectedTab("/");
                  }}
                >
                  <i className="material-icons-outlined">home</i>
                  {configAppConst.home}
                </NavLink>
              </NavItem>
            )}
            {(userRole === "User" ||
              userRole === "Partner" ||
              isAllowShow("/marketwatch")) && (
              <NavItem>
                <NavLink
                  className={selectedTab === "/marketwatch" ? "current" : ""}
                  to="/marketwatch"
                  tag={NavLinkRRD}
                  onClick={() => {
                    closeCollapse();
                    setSelectedTab("/marketwatch");
                  }}
                >
                  <i className="material-icons-outlined">assessment</i>
                  {configAppConst.marketWatch}
                </NavLink>
              </NavItem>
            )}
            {(userRole === "User" ||
              userRole === "Partner" ||
              isAllowShow("/order-book")) && (
              <NavItem>
                <NavLink
                  className={selectedTab === "/order-book" ? "current" : ""}
                  to="/order-book"
                  tag={NavLinkRRD}
                  onClick={() => {
                    closeCollapse();
                    setSelectedTab("/order-book");
                  }}
                >
                  <i className="material-icons-outlined">business</i>
                  {configAppConst.orderBook}
                </NavLink>
              </NavItem>
            )}
            {(userRole === "User" ||
              userRole === "Partner" ||
              isAllowShow("/trade-book")) && (
              <NavItem>
                <NavLink
                  className={selectedTab === "/trade-book" ? "current" : ""}
                  to="/trade-book"
                  tag={NavLinkRRD}
                  onClick={() => {
                    closeCollapse();
                    setSelectedTab("/trade-book");
                  }}
                >
                  <i className="material-icons-outlined">local_mall</i>
                  {configAppConst.tradeBook}
                </NavLink>
              </NavItem>
            )}
            {(userRole === "User" ||
              userRole === "Partner" ||
              isAllowShow("/position-report")) && (
              <NavItem>
                <NavLink
                  className={
                    selectedTab === "/position-report" ? "current" : ""
                  }
                  to="/position-report"
                  tag={NavLinkRRD}
                  onClick={() => {
                    closeCollapse();
                    setSelectedTab("/position-report");
                  }}
                >
                  <i className="material-icons-outlined">summarize</i>
                  {configAppConst.positionReport}
                </NavLink>
              </NavItem>
            )}
            {(userRole === "Super Admin" ||
              userRole === "Admin" ||
              userRole === "Super Master" ||
              userRole === "Master") &&
              isAllowShow("/position-report") && (
                <NavItem>
                  <NavLink
                    className={
                      selectedTab === "/position-report2" ? "current" : ""
                    }
                    to="/position-report2"
                    tag={NavLinkRRD}
                    onClick={() => {
                      closeCollapse();
                      setSelectedTab("/position-report2");
                    }}
                  >
                    <i className="material-icons-outlined">summarize</i>
                    Position Report 2
                  </NavLink>
                </NavItem>
              )}
            {(userRole === "User" ||
              userRole === "Partner" ||
              isAllowShow("/banned-blocked-scripts")) && (
              <NavItem>
                <NavLink
                  className={
                    selectedTab === "/banned-blocked-scripts" ? "current" : ""
                  }
                  to="/banned-blocked-scripts"
                  tag={NavLinkRRD}
                  onClick={() => {
                    closeCollapse();
                    setSelectedTab("/banned-blocked-scripts");
                  }}
                >
                  <i className="material-icons-outlined">block</i>
                  {configAppConst.bannedBlockedScrips}
                </NavLink>
              </NavItem>
            )}
            {(userRole === "User" ||
              userRole === "Partner" ||
              isAllowShow("/max-quantity-details")) && (
              <NavItem>
                <NavLink
                  className={
                    selectedTab === "/max-quantity-details" ? "current" : ""
                  }
                  to="/max-quantity-details"
                  tag={NavLinkRRD}
                  onClick={() => {
                    closeCollapse();
                    setSelectedTab("/max-quantity-details");
                  }}
                >
                  <i className="material-icons-outlined">
                    production_quantity_limits
                  </i>
                  {configAppConst.maxQuantityDetails}
                </NavLink>
              </NavItem>
            )}
            {(userRole === "User" ||
              userRole === "Partner" ||
              userRole === "Sub Broker") && (
              <NavItem>
                <NavLink
                  onClick={() => {
                    setReportCollapse(!reportCollapse);
                  }}
                  role="button"
                  aria-expanded={reportCollapse}
                >
                  <i className="material-icons-outlined">description</i>
                  {configAppConst.reports}
                  <i className="material-icons-outlined chevron-collapse ml-auto text-right">
                    keyboard_arrow_down
                  </i>
                </NavLink>
                <Collapse isOpen={reportCollapse}>
                  <Nav className="flex-column">
                    <NavItem>
                      <NavLink
                        className={
                          selectedTab === "/reports/trade-report"
                            ? "current"
                            : ""
                        }
                        to="/reports/trade-report"
                        tag={NavLinkRRD}
                        onClick={() => {
                          closeCollapse();
                          setSelectedTab("/reports/trade-report");
                        }}
                      >
                        {configAppConst.tradeReport}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          selectedTab === "/reports/ledger-report"
                            ? "current"
                            : ""
                        }
                        to="/reports/ledger-report"
                        tag={NavLinkRRD}
                        onClick={() => {
                          closeCollapse();
                          setSelectedTab("/reports/ledger-report");
                        }}
                      >
                        {configAppConst.ledgerReport}
                      </NavLink>
                    </NavItem>
                    {userRole === "User" && (
                      <>
                        <NavItem>
                          <NavLink
                            className={
                              selectedTab === "/reports/rejection-log"
                                ? "current"
                                : ""
                            }
                            to="/reports/rejection-log"
                            tag={NavLinkRRD}
                            onClick={() => {
                              closeCollapse();
                              setSelectedTab("/reports/rejection-log");
                            }}
                          >
                            {configAppConst.orderTradeRejectionLog}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={
                              selectedTab === "/reports/log-report"
                                ? "current"
                                : ""
                            }
                            to="/reports/log-report"
                            tag={NavLinkRRD}
                            onClick={() => {
                              closeCollapse();
                              setSelectedTab("/reports/log-report");
                            }}
                          >
                            {configAppConst.orderTradeEditDeleteLog}
                          </NavLink>
                        </NavItem>
                      </>
                    )}
                  </Nav>
                </Collapse>
              </NavItem>
            )}
            {!userPartnerEnum.includes(userRole) && (
              <>
                {isMasterAllowShow([
                  "/master/account",
                  "/master/brokerage",
                  "/master/partnership",
                ]) && (
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        setMasterCollapse(!masterCollapse);
                      }}
                      role="button"
                      aria-expanded={masterCollapse}
                    >
                      <i className="material-icons-outlined">pie_chart</i>
                      {configAppConst.master}
                      <i className="material-icons-outlined chevron-collapse ml-auto text-right">
                        keyboard_arrow_down
                      </i>
                    </NavLink>
                    <Collapse isOpen={masterCollapse}>
                      <Nav className="flex-column">
                        {isAllowShow("/master/account") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/master/account"
                                  ? "current"
                                  : ""
                              }
                              to="/master/account"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/master/account");
                              }}
                            >
                              {configAppConst.account}
                            </NavLink>
                          </NavItem>
                        )}
                        {isMasterRole && isAllowShow("/master/brokerage") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/master/brokerage"
                                  ? "current"
                                  : ""
                              }
                              to="/master/brokerage"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/master/brokerage");
                              }}
                            >
                              {configAppConst.brokerage}
                            </NavLink>
                          </NavItem>
                        )}
                        {isMasterRole && isAllowShow("/master/partnership") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/master/partnership"
                                  ? "current"
                                  : ""
                              }
                              to="/master/partnership"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/master/partnership");
                              }}
                            >
                              {configAppConst.partnership}
                            </NavLink>
                          </NavItem>
                        )}
                        {isMasterRole && isAllowShow("/master/valan") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/master/valan" ? "current" : ""
                              }
                              to="/master/valan"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/master/valan");
                              }}
                            >
                              {configAppConst.valan}
                            </NavLink>
                          </NavItem>
                        )}
                      </Nav>
                    </Collapse>
                  </NavItem>
                )}
                {isMasterRole &&
                  isMasterAllowShow([
                    "/transaction/trade-entry",
                    "/transaction/trade-utility",
                    "/transaction/bill-creation",
                    "/transaction/upload-bhavCopy",
                    "/transaction/upload-bhavCopy-data",
                  ]) && (
                    <NavItem>
                      <NavLink
                        onClick={() => {
                          setTransactionCollapse(!transactionCollapse);
                        }}
                        role="button"
                        aria-expanded={transactionCollapse}
                      >
                        <i className="material-icons-outlined">receipt_long</i>
                        {configAppConst.transaction}
                        <i className="material-icons-outlined chevron-collapse ml-auto text-right">
                          keyboard_arrow_down
                        </i>
                      </NavLink>
                      <Collapse isOpen={transactionCollapse}>
                        <Nav className="flex-column">
                          {isAllowShow("/transaction/trade-entry") && (
                            <NavItem>
                              <NavLink
                                className={
                                  selectedTab === "/transaction/trade-entry"
                                    ? "current"
                                    : ""
                                }
                                to="/transaction/trade-entry"
                                tag={NavLinkRRD}
                                onClick={() => {
                                  closeCollapse();
                                  setSelectedTab("/transaction/trade-entry");
                                }}
                              >
                                {configAppConst.tradeEntry}
                              </NavLink>
                            </NavItem>
                          )}
                          {isAllowShow("/transaction/trade-utility") && (
                            <NavItem>
                              <NavLink
                                className={
                                  selectedTab === "/transaction/trade-utility"
                                    ? "current"
                                    : ""
                                }
                                to="/transaction/trade-utility"
                                tag={NavLinkRRD}
                                onClick={() => {
                                  closeCollapse();
                                  setSelectedTab("/transaction/trade-utility");
                                }}
                              >
                                {configAppConst.tradeUtility}
                              </NavLink>
                            </NavItem>
                          )}
                          {isAllowShow("/transaction/bill-creation") && (
                            <NavItem>
                              <NavLink
                                className={
                                  selectedTab === "/transaction/bill-creation"
                                    ? "current"
                                    : ""
                                }
                                to="/transaction/bill-creation"
                                tag={NavLinkRRD}
                                onClick={() => {
                                  closeCollapse();
                                  setSelectedTab("/transaction/bill-creation");
                                }}
                              >
                                {configAppConst.billCreation}
                              </NavLink>
                            </NavItem>
                          )}
                          {isAllowShow("/transaction/upload-bhavCopy") && (
                            <NavItem>
                              <NavLink
                                className={
                                  selectedTab === "/transaction/upload-bhavCopy"
                                    ? "current"
                                    : ""
                                }
                                to="/transaction/upload-bhavCopy"
                                tag={NavLinkRRD}
                                onClick={() => {
                                  closeCollapse();
                                  setSelectedTab(
                                    "/transaction/upload-bhavCopy"
                                  );
                                }}
                              >
                                {configAppConst.uploadBhavCopy}
                              </NavLink>
                            </NavItem>
                          )}
                          {isAllowShow("/transaction/upload-bhavCopy-data") && (
                            <NavItem>
                              <NavLink
                                className={
                                  selectedTab ===
                                  "/transaction/upload-bhavCopy-data"
                                    ? "current"
                                    : ""
                                }
                                to="/transaction/upload-bhavCopy-data"
                                tag={NavLinkRRD}
                                onClick={() => {
                                  closeCollapse();
                                  setSelectedTab(
                                    "/transaction/upload-bhavCopy-data"
                                  );
                                }}
                              >
                                {configAppConst.bhavCopyData}
                              </NavLink>
                            </NavItem>
                          )}
                        </Nav>
                      </Collapse>
                    </NavItem>
                  )}
                {isMasterAllowShow([
                  "/finance/cash-entry",
                  "/finance/jv-entry",
                  "/finance/bank-details",
                  "/finance/pay-in",
                  "/finance/pay-out",
                ]) && (
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        setFinanceCollapse(!financeCollapse);
                      }}
                      role="button"
                      aria-expanded={financeCollapse}
                    >
                      <i className="material-icons-outlined">monetization_on</i>
                      {configAppConst.finance}
                      <i className="material-icons-outlined chevron-collapse ml-auto text-right">
                        keyboard_arrow_down
                      </i>
                    </NavLink>
                    <Collapse isOpen={financeCollapse}>
                      <Nav className="flex-column">
                        {isAllowShow("/finance/cash-entry") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/finance/cash-entry"
                                  ? "current"
                                  : ""
                              }
                              to="/finance/cash-entry"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/finance/cash-entry");
                              }}
                            >
                              {configAppConst.cashEntry}
                            </NavLink>
                          </NavItem>
                        )}
                        {isAllowShow("/finance/jv-entry") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/finance/jv-entry"
                                  ? "current"
                                  : ""
                              }
                              to="/finance/jv-entry"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/finance/jv-entry");
                              }}
                            >
                              {configAppConst.jVEntry}
                            </NavLink>
                          </NavItem>
                        )}

                        {isAllowShow("/finance/pay-in") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/finance/pay-in"
                                  ? "current"
                                  : ""
                              }
                              to="/finance/pay-in"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/finance/pay-in");
                              }}
                            >
                              Pay In
                            </NavLink>
                          </NavItem>
                        )}
                        {isAllowShow("/finance/pay-out") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/finance/pay-out"
                                  ? "current"
                                  : ""
                              }
                              to="/finance/pay-out"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/finance/pay-out");
                              }}
                            >
                              Pay Out
                            </NavLink>
                          </NavItem>
                        )}
                        {isAllowShow("/finance/bank-details") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/finance/bank-details"
                                  ? "current"
                                  : ""
                              }
                              to="/finance/bank-details"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/finance/bank-details");
                              }}
                            >
                              Bank Detail
                            </NavLink>
                          </NavItem>
                        )}
                      </Nav>
                    </Collapse>
                  </NavItem>
                )}
                {isMasterAllowShow([
                  "/reports/ledger-report",
                  "/reports/trade-report",
                  "/admin/trial-balance",
                  "/reports/log-report",
                  "/reports/rejection-log",
                ]) && (
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        setReportCollapse(!reportCollapse);
                      }}
                      role="button"
                      aria-expanded={reportCollapse}
                    >
                      <i className="material-icons-outlined">description</i>
                      {configAppConst.reports}
                      <i className="material-icons-outlined chevron-collapse ml-auto text-right">
                        keyboard_arrow_down
                      </i>
                    </NavLink>
                    <Collapse isOpen={reportCollapse}>
                      <Nav className="flex-column">
                        {isAllowShow("/reports/trade-report") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/reports/trade-report"
                                  ? "current"
                                  : ""
                              }
                              to="/reports/trade-report"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/reports/trade-report");
                              }}
                            >
                              {configAppConst.tradeReport}
                            </NavLink>
                          </NavItem>
                        )}
                        {isAllowShow("/reports/ledger-report") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/reports/ledger-report"
                                  ? "current"
                                  : ""
                              }
                              to="/reports/ledger-report"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/reports/ledger-report");
                              }}
                            >
                              {configAppConst.ledgerReport}
                            </NavLink>
                          </NavItem>
                        )}
                        {isAllowShow("/admin/trial-balance") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/admin/trial-balance"
                                  ? "current"
                                  : ""
                              }
                              to="/admin/trial-balance"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/admin/trial-balance");
                              }}
                            >
                              {configAppConst.trialBalance}
                            </NavLink>
                          </NavItem>
                        )}
                        {isAllowShow("/reports/log-report") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/reports/log-report"
                                  ? "current"
                                  : ""
                              }
                              to="/reports/log-report"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/reports/log-report");
                              }}
                            >
                              {configAppConst.orderTradeEditDeleteLog}
                            </NavLink>
                          </NavItem>
                        )}
                        {isAllowShow("/reports/rejection-log") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/reports/rejection-log"
                                  ? "current"
                                  : ""
                              }
                              to="/reports/rejection-log"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/reports/rejection-log");
                              }}
                            >
                              {configAppConst.orderTradeRejectionLog}
                            </NavLink>
                          </NavItem>
                        )}
                      </Nav>
                    </Collapse>
                  </NavItem>
                )}
                {isMasterAllowShow([
                  "/settings/quantity",
                  "/settings/script-management",
                  "/settings/call-put-setting",
                  "/settings/banned-scripts",
                  "/settings/block-scripts",
                  "/settings/transfer",
                  "/settings/expiry-validation",
                  "/settings/script-max-value",
                  "/settings/client-segment",
                  "/settings/client-order-limit",
                ]) && (
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        setSettingCollapse(!settingCollapse);
                      }}
                      role="button"
                      aria-expanded={settingCollapse}
                    >
                      <i className="material-icons-outlined">settings</i>
                      {configAppConst.settings}
                      <i className="material-icons-outlined chevron-collapse ml-auto text-right">
                        keyboard_arrow_down
                      </i>
                    </NavLink>
                    <Collapse isOpen={settingCollapse}>
                      <Nav className="flex-column">
                        {isAllowShow("/settings/quantity") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/settings/quantity"
                                  ? "current"
                                  : ""
                              }
                              to="/settings/quantity"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/settings/quantity");
                              }}
                            >
                              {configAppConst.quantitySetting}
                            </NavLink>
                          </NavItem>
                        )}
                        {isAllowShow("/settings/script-management") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/settings/script-management"
                                  ? "current"
                                  : ""
                              }
                              to="/settings/script-management"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/settings/script-management");
                              }}
                            >
                              {configAppConst.scriptManagement}
                            </NavLink>
                          </NavItem>
                        )}
                        {isAllowShow("/settings/banned-scripts") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/settings/banned-scripts"
                                  ? "current"
                                  : ""
                              }
                              to="/settings/banned-scripts"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/settings/banned-scripts");
                              }}
                            >
                              {configAppConst.bannedScripts}
                            </NavLink>
                          </NavItem>
                        )}
                        {isAllowShow("/settings/block-scripts") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/settings/block-scripts"
                                  ? "current"
                                  : ""
                              }
                              to="/settings/block-scripts"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/settings/block-scripts");
                              }}
                            >
                              {configAppConst.blockScript}
                            </NavLink>
                          </NavItem>
                        )}
                        {isMasterRole && isAllowShow("/settings/transfer") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/settings/transfer"
                                  ? "current"
                                  : ""
                              }
                              to="/settings/transfer"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/settings/transfer");
                              }}
                            >
                              {configAppConst.transferSetting}
                            </NavLink>
                          </NavItem>
                        )}
                        {isMasterRole &&
                          isAllowShow("/settings/expiry-validation") && (
                            <NavItem>
                              <NavLink
                                className={
                                  selectedTab === "/settings/expiry-validation"
                                    ? "current"
                                    : ""
                                }
                                to="/settings/expiry-validation"
                                tag={NavLinkRRD}
                                onClick={() => {
                                  closeCollapse();
                                  setSelectedTab("/settings/expiry-validation");
                                }}
                              >
                                {configAppConst.expiryValidation}
                              </NavLink>
                            </NavItem>
                          )}
                        {isMasterRole &&
                          isAllowShow("/settings/script-max-value") && (
                            <NavItem>
                              <NavLink
                                className={
                                  selectedTab === "/settings/script-max-value"
                                    ? "current"
                                    : ""
                                }
                                to="/settings/script-max-value"
                                tag={NavLinkRRD}
                                onClick={() => {
                                  closeCollapse();
                                  setSelectedTab("/settings/script-max-value");
                                }}
                              >
                                {configAppConst.scriptMaxValue}
                              </NavLink>
                            </NavItem>
                          )}
                        {isMasterRole &&
                          isAllowShow("/settings/client-segment") && (
                            <NavItem>
                              <NavLink
                                className={
                                  selectedTab === "/settings/client-segment"
                                    ? "current"
                                    : ""
                                }
                                to="/settings/client-segment"
                                tag={NavLinkRRD}
                                onClick={() => {
                                  closeCollapse();
                                  setSelectedTab("/settings/client-segment");
                                }}
                              >
                                {configAppConst.clientSegmentSetting}
                              </NavLink>
                            </NavItem>
                          )}
                        {isMasterRole &&
                          isAllowShow("/settings/client-order-limit") && (
                            <NavItem>
                              <NavLink
                                className={
                                  selectedTab === "/settings/client-order-limit"
                                    ? "current"
                                    : ""
                                }
                                to="/settings/client-order-limit"
                                tag={NavLinkRRD}
                                onClick={() => {
                                  closeCollapse();
                                  setSelectedTab(
                                    "/settings/client-order-limit"
                                  );
                                }}
                              >
                                {configAppConst.clientOrderLimit}
                              </NavLink>
                            </NavItem>
                          )}
                        {isMasterRole &&
                          isAllowShow("/settings/script-max-qty-setting") && (
                            <NavItem>
                              <NavLink
                                className={
                                  selectedTab ===
                                  "/settings/script-max-qty-setting"
                                    ? "current"
                                    : ""
                                }
                                to="/settings/script-max-qty-setting"
                                tag={NavLinkRRD}
                                onClick={() => {
                                  closeCollapse();
                                  setSelectedTab(
                                    "/settings/script-max-qty-setting"
                                  );
                                }}
                              >
                                {configAppConst.scriptMinQtySetting}
                              </NavLink>
                            </NavItem>
                          )}
                        {isMasterRole &&
                          isAllowShow("/settings/option-strike-validation") && (
                            <NavItem>
                              <NavLink
                                className={
                                  selectedTab ===
                                  "/settings/option-strike-validation"
                                    ? "current"
                                    : ""
                                }
                                to="/settings/option-strike-validation"
                                tag={NavLinkRRD}
                                onClick={() => {
                                  closeCollapse();
                                  setSelectedTab(
                                    "/settings/option-strike-validation"
                                  );
                                }}
                              >
                                {configAppConst.optionStrikeValidation}
                              </NavLink>
                            </NavItem>
                          )}
                        {isMasterRole && isAllowShow("/settings/cPanel") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/settings/cPanel"
                                  ? "current"
                                  : ""
                              }
                              to="/settings/cPanel"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/settings/cPanel");
                              }}
                            >
                              {configAppConst.cPanel}
                            </NavLink>
                          </NavItem>
                        )}
                        {isMasterRole &&
                          isAllowShow("/settings/call-put-setting") && (
                            <NavItem>
                              <NavLink
                                className={
                                  selectedTab === "/settings/call-put-setting"
                                    ? "current"
                                    : ""
                                }
                                to="/settings/call-put-setting"
                                tag={NavLinkRRD}
                                onClick={() => {
                                  closeCollapse();
                                  setSelectedTab("/settings/call-put-setting");
                                }}
                              >
                                {configAppConst.callPutsetting}
                              </NavLink>
                            </NavItem>
                          )}
                      </Nav>
                    </Collapse>
                  </NavItem>
                )}
                {isMasterAllowShow([
                  "/inspect/trade-inspection",
                  "/inspect/same-ip",
                  "/inspect/top-gainers-and-losers",
                ]) && (
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        setInspectCollapse(!inspectCollapse);
                      }}
                      role="button"
                      aria-expanded={inspectCollapse}
                    >
                      <i className="material-symbols-outlined">mystery</i>
                      {configAppConst.inspect}
                      <i className="material-icons-outlined chevron-collapse ml-auto text-right">
                        keyboard_arrow_down
                      </i>
                    </NavLink>
                    <Collapse isOpen={inspectCollapse}>
                      <Nav className="flex-column">
                        {isAllowShow("/inspect/same-ip") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/inspect/same-ip"
                                  ? "current"
                                  : ""
                              }
                              to="/inspect/same-ip"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/inspect/same-ip");
                              }}
                            >
                              {configAppConst.sameIp}
                            </NavLink>
                          </NavItem>
                        )}
                        {isAllowShow("/inspect/trade-inspection") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab === "/inspect/trade-inspection"
                                  ? "current"
                                  : ""
                              }
                              to="/inspect/trade-inspection"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab("/inspect/trade-inspection");
                              }}
                            >
                              {configAppConst.tradeInspection}
                            </NavLink>
                          </NavItem>
                        )}
                        {isAllowShow("/inspect/top-gainers-and-losers") && (
                          <NavItem>
                            <NavLink
                              className={
                                selectedTab ===
                                "/inspect/top-gainers-and-losers"
                                  ? "current"
                                  : ""
                              }
                              to="/inspect/top-gainers-and-losers"
                              tag={NavLinkRRD}
                              onClick={() => {
                                closeCollapse();
                                setSelectedTab(
                                  "/inspect/top-gainers-and-losers"
                                );
                              }}
                            >
                              {configAppConst.TopGainersAndLosers}
                            </NavLink>
                          </NavItem>
                        )}
                      </Nav>
                    </Collapse>
                  </NavItem>
                )}
              </>
            )}
          </Nav>
        </Collapse>
      </Row>
      <div className="marquee_mobile">
        <Row>
          <marquee width="100%" direction="left">
            <h3 className="mb-0 text-white">
              {notificationList?.length > 0 &&
                notificationList?.map((item, index) => {
                  if (++index === notificationList.length) {
                    return <span key={index}>{item.notificationText}</span>;
                  } else if (notificationList.length > 1) {
                    return <span key={index}>{item.notificationText} || </span>;
                  }
                  return <span key={index}>{item.notificationText}</span>;
                })}
              {(notificationList === undefined ||
                notificationList?.length === 0) && (
                <h3 className="mb-0 text-white">
                  No real money involved in this ID (App). This is a Virtual
                  Trading Application which has all the features to trade. This
                  application is used for exchanging views on markets for
                  individual students for training purpose only.
                </h3>
              )}
            </h3>
          </marquee>
        </Row>
      </div>
      <Modal
        className="modal-dialog-centered warning_modal_wrapper"
        isOpen={openModalWarning}
      >
        <div className="modal-body">
          <p>
            Due to some inactivity or security reasons stop your website, please
            close the developer tool.
          </p>
          <p>Thank you for your support.</p>
        </div>
      </Modal>
    </Container>
  );
}

export default Sidebar;
